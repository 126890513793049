export const AppProtectedRoutes = {
  Home: "/",
  Settings: "/settings",
  Pages: "/pages",
  PagesEdit: "/pages/edit/:id",
  TeamMembers: "/team-members",
  TeamMembersEdit: "/team-members/edit/:id",
  Partners: "/partners",
  PartnersEdit: "/partners/edit/:id",
  Educations: "/educations",
  EducationsEdit: "/educations/edit/:id",
  Experts: "/experts",
  ExpertsEdit: "/experts/edit/:id",
  Events: "/events",
  EventsEdit: "/events/edit/:id",
  News: "/news",
  NewsEdit: "/news/edit/:id",
  Reviews: "/reviews",
  ReviewsEdit: "/reviews/edit/:id",
  Users: "/users",
  UsersEdit: "/users/edit/:id",
  Subscribers: "/subscribers",
  Galleries: "/galleries",
  GalleriesEdit: "/galleries/edit/:id",
  Graduates: "/graduates",
  GraduatesEdit: "/graduates/edit/:id",
  Dialogues: "/dialogues",
  DialoguesView: "/dialogues/view/:id",
  Notifications: "/notifications",
  NotificationsView: "/notifications/view/:id",
  Instructions: "/instructions",
  Dictionaries: "/dictionaries",
  DictionariesEdit: "/dictionaries/edit/:id",
  Structures: "/structures",
  SigningsEdit: "/signings/edit/:id",
  Signings: "/signings"
};

export const AppProtectedCreatingRoutes = {
  PagesEdit: "/pages/create",
  TeamMembersEdit: "/team-members/create",
  PartnersEdit: "/partners/create",
  EducationsEdit: "/educations/create",
  ExpertsEdit: "/experts/create",
  EventsEdit: "/events/create",
  NewsEdit: "/news/create",
  ReviewsEdit: "/reviews/create",
  UsersEdit: "/users/create",
  GalleriesEdit: "/galleries/create",
  GraduatesEdit: "/graduates/create",
  SigningsEdit: "/signings/create"
};

export const AppGuestRoutes = {
  Login: "/login",
  Registration: "/registration"
};
