/* eslint-disable no-secrets/no-secrets */
import { create } from "zustand";

const storageKey = "__token";

export type AuthState = {
  token: string;
  auth: (_token: string) => void;
  unAuth: () => void;
};

export const useAuthStore = create<AuthState>(
  (set): AuthState => ({
    token: localStorage.getItem(storageKey) || "",

    auth: (token: string) => {
      localStorage.setItem(storageKey, token);
      set(() => ({ token }));
    },

    unAuth: () => {
      localStorage.removeItem(storageKey);
      set(() => ({ token: "" }));
    }
  })
);
