import React from "react";
import { useNavigate } from "react-router-dom";
import { List } from "@mui/material";
import { DrawerListItem } from "../DrawerListItem";
import { TPath } from "~/app/providers/Paths";
import clsx from "clsx";

type Props = {
  paths: TPath[];
  open: boolean;
  activePath: string | null;
  className?: string;
};

export const DrawerList: React.FC<Props> = ({ paths, open, activePath, className = "" }) => {
  const history = useNavigate();

  return (
    <List className={clsx("overflow-y-auto overflow-x-hidden", className)}>
      {paths.map((item) => (
        <DrawerListItem
          key={item.id}
          active={item.path === activePath}
          open={open}
          icon={item.icon}
          label={item.label}
          onClick={() => history(item.path)}
        />
      ))}
    </List>
  );
};
