import React, { ReactElement } from "react";
import { createCtx } from "~/shared/lib/context";
import { AppProtectedRoutes } from "~shared/routes";
import HomeIcon from "@mui/icons-material/Home";
import LayersIcon from "@mui/icons-material/Layers";
import GroupsIcon from "@mui/icons-material/Groups";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SchoolIcon from "@mui/icons-material/School";
import ScienceIcon from "@mui/icons-material/Science";
import EventIcon from "@mui/icons-material/Event";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReviewsIcon from "@mui/icons-material/Reviews";
import PeopleIcon from "@mui/icons-material/People";
import CollectionsIcon from "@mui/icons-material/Collections";
// import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import BookIcon from "@mui/icons-material/Book";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Campaign";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

export type TPath = {
  id: number;
  label: string;
  path: string;
  icon: ReactElement;
  children?: TPath[];
};

const paths: TPath[] = [
  {
    label: "Home",
    path: AppProtectedRoutes.Home,
    icon: <HomeIcon />
  },
  {
    label: "Pages",
    path: AppProtectedRoutes.Pages,
    icon: <LayersIcon />
  },
  {
    label: "Team members",
    path: AppProtectedRoutes.TeamMembers,
    icon: <GroupsIcon />
  },
  {
    label: "Partners",
    path: AppProtectedRoutes.Partners,
    icon: <HandshakeIcon />
  },
  {
    label: "Structures",
    path: AppProtectedRoutes.Structures,
    icon: <AccountTreeIcon />
  },
  {
    label: "Education programs",
    path: AppProtectedRoutes.Educations,
    icon: <SchoolIcon />
  },
  {
    label: "Experts",
    path: AppProtectedRoutes.Experts,
    icon: <ScienceIcon />
  },
  {
    label: "Events",
    path: AppProtectedRoutes.Events,
    icon: <EventIcon />
  },
  {
    label: "News",
    path: AppProtectedRoutes.News,
    icon: <NewspaperIcon />
  },
  {
    label: "Reviews",
    path: AppProtectedRoutes.Reviews,
    icon: <ReviewsIcon />
  },
  {
    label: "Gallery",
    path: AppProtectedRoutes.Galleries,
    icon: <CollectionsIcon />
  },
  // {
  //   label: "Graduates",
  //   path: AppProtectedRoutes.Graduates,
  //   icon: <CastForEducationIcon />
  // },
  {
    label: "Dialogues",
    path: AppProtectedRoutes.Dialogues,
    icon: <EmailIcon />
  },
  {
    label: "Notifications",
    path: AppProtectedRoutes.Notifications,
    icon: <NotificationsIcon />
  },
  {
    label: "Users",
    path: AppProtectedRoutes.Users,
    icon: <PeopleIcon />
  },
  {
    label: "Certificate packages",
    path: AppProtectedRoutes.Signings,
    icon: <FolderCopyIcon />
  },
  {
    label: "Subscriptions",
    path: AppProtectedRoutes.Subscribers,
    icon: <SubscriptionsIcon />
  },
  {
    label: "Instructions",
    path: AppProtectedRoutes.Instructions,
    icon: <IntegrationInstructionsIcon />
  },
  {
    label: "Dictionaries",
    path: AppProtectedRoutes.Dictionaries,
    icon: <BookIcon />
  },
  {
    label: "Settings",
    path: AppProtectedRoutes.Settings,
    icon: <SettingsIcon />
  }
].map((item, i) => ({ ...item, id: i + 1 }));

export const [usePaths, PathsProvider] = createCtx(paths, { name: "PathsProvider" });
