import React, { PropsWithChildren } from "react";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import { DropdownIcon } from "~/shared/components/Icons";

export const colors = {
  primary: "#118782",
  primary5: "rgba(17,135,130,0.05)",
  primary10: "rgba(17,135,130,0.1)",
  primary30: "rgba(17,135,130,0.3)",
  primaryDark: "#0d6965",
  primaryLight: "#E5EBE7",
  secondary: "#08205C",
  mainBg: "#ffffff",
  secondaryBg: "#F8FAFC",
  mainError: "#D23C3C",
  mainText: "#1C1C1C",
  secondaryText: "#828282",
  blue: "#2F80ED"
};

const buttonStyle = {
  height: 48,
  minWidth: 140,
  padding: "12px 24px",
  borderRadius: 8,
  boxShadow: "none",
  "& .MuiTypography-root": {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px"
  }
};

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {}
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0.4rem 0.4rem 0.4rem 0.4rem"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: colors.mainBg,
          zIndex: 1,
          "& .MuiTableRow-root": {
            zIndex: 1
          },
          "& .MuiTableCell-head": {
            background: colors.mainBg,
            zIndex: 1
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:hover .MuiTableCell-body": {
            position: "relative"
          },
          "& .MuiTableRow-root:hover .MuiTableCell-body:after": {
            content: "''",
            width: "100%",
            height: "100%",
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none"
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          position: "relative",
          zIndex: 0
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: 2,
          height: 60,
          backgroundColor: colors.mainBg
        },
        head: {
          paddingLeft: 8
        },
        body: {
          padding: 8
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "0 16px 0 8px",
          marginLeft: 6,
          transform: "translate(10px, -14px) scale(1)",
          backgroundColor: colors.mainBg,
          p: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "24px",
            color: colors.mainText
          }
        }
      },
      defaultProps: {
        shrink: true
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: "16px 16px 16px 24px",
          border: "none",
          "&:not(.MuiInputBase-inputMultiline)": {
            height: 52
          },
          fieldset: {
            borderWidth: "1px !important",
            borderColor: colors.primaryLight,
            borderRadius: 8,
            padding: "0 24px 0 16px"
          },
          "&:hover, &:focus": {
            fieldset: {
              border: `1px solid ${colors.primary} !important`
            }
          },
          "&.Mui-error": {
            "&:hover, &:focus": {
              fieldset: {
                border: `1px solid ${colors.mainError} !important`
              }
            }
          }
        },
        input: {
          height: 52,
          padding: "0 !important"
        },
        multiline: {
          height: "auto !important",
          padding: "16px 16px 16px 24px !important"
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "0 60px 0 24px !important"
        },
        popupIndicator: {
          marginRight: 4
        }
      },
      defaultProps: {
        popupIcon: <DropdownIcon />
      }
    },

    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true
        }
      },
      styleOverrides: {
        root: {
          "& input[type=number]": {
            MozAppearance: "textfield"
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          }
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: "44px !important",
          height: 52,
          display: "flex",
          alignItems: "center"
        },
        icon: {
          top: "unset",
          right: 16,
          cursor: "pointer"
        }
      },
      defaultProps: {
        IconComponent: DropdownIcon
      }
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        track: {
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.3
          }
        },
        thumb: {
          boxShadow: "0px 2px 4px rgba(83, 83, 83, 0.2)"
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "&[type='submit'], &[type='button']": {
            backgroundColor: colors.primary,
            "&:hover": {
              backgroundColor: colors.primaryDark
            },
            "&:disabled": {
              backgroundColor: colors.secondaryText,
              color: "white"
            }
          }
        },
        outlined: buttonStyle,
        contained: buttonStyle
      }
    },

    MuiMenu: {
      defaultProps: {
        style: {
          height: "300px"
        }
      }
    }
  },
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.mainError
    }
  }
});

export const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
