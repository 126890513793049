import React, { FC, ReactNode, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { BaseProtectedLayout } from "~/layouts/BaseProtectedLayout";
import { AppGuestRoutes, AppProtectedCreatingRoutes, AppProtectedRoutes } from "~/shared/routes";
import { GuestRoutes } from "~/modules/GuestRoutes";
import { useCheckAuth } from "~/shared/hooks/useCheckAuth";

export const createRoutes = <T extends object>(
  routes: T,
  Wrapper: FC<{ children?: ReactNode }>
) => {
  const entries = Object.entries(routes) as [string, string][];
  return entries.map(([page, path]) => {
    const LazyPage = lazy(() =>
      import(`~/pages/${page}`).then((module) => ({ default: module[page] }))
    );

    const route = (
      <Route
        key={path}
        path={path}
        element={
          <Wrapper>
            <LazyPage />
          </Wrapper>
        }
      />
    );

    return route;
  });
};

const allProtectedRoutes = { ...AppProtectedRoutes, ...AppProtectedCreatingRoutes };

const guestRoutes = createRoutes<typeof AppGuestRoutes>(AppGuestRoutes, GuestRoutes);
const protectedBaseRoutes = createRoutes<typeof AppProtectedRoutes>(
  AppProtectedRoutes,
  BaseProtectedLayout
);
const protectedCreatngRoutes = createRoutes<typeof AppProtectedCreatingRoutes>(
  AppProtectedCreatingRoutes,
  BaseProtectedLayout
);

export const Routing: React.FC = () => {
  const location = useLocation();
  const isOnProtectedRoutes = Object.entries(allProtectedRoutes)
    .find(([, route]) => route === location.pathname)
    ?.at(0);

  useCheckAuth(!!isOnProtectedRoutes);

  return (
    <Routes>
      {guestRoutes}
      {protectedBaseRoutes}
      {protectedCreatngRoutes}
    </Routes>
  );
};
