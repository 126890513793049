import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Text } from "~shared/components/Text";
import { UsersModel } from "~/generated/graphql";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { HeaderTabs } from "~/modules/Header/components/HeaderTabs";
import { useAuthStore } from "~stores/auth";
import { AppGuestRoutes } from "~/shared/routes";

export type AppToolbarProps = {
  user?: Partial<UsersModel>;
};

export const AppToolbar: React.FC<AppToolbarProps> = ({ user }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const unAuth = useAuthStore((state) => state.unAuth);

  const history = useNavigate();

  const handleLogout = () => {
    unAuth();
    history(AppGuestRoutes.Login);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setMenuOpen(open);
  };

  return (
    <AppBar position='static' className='bg-white rounded-xl shadow-lg w-full mx-auto'>
      <Box className='flex justify-between items-center sm-hidden'>
        <IconButton className='lg:opacity-0 lg:pointer-events-none' onClick={toggleDrawer(true)}>
          <MenuIcon className='text-4xl' />
        </IconButton>
        <Box className='flex items-center justify-end'>
          <Text className='text-primary'>{user?.name ?? ""}</Text>
          <Toolbar>
            <Box className='ml-auto'>
              <Tooltip title='Open settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogout}>
                  <Text>Logout</Text>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Box>
        <Drawer anchor='top' open={menuOpen} onClose={toggleDrawer(false)}>
          <HeaderTabs />
        </Drawer>
      </Box>
    </AppBar>
  );
};
