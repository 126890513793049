import { useState, useEffect } from "react";

const storageKey = "__drawer-open";

export const useDrawer = () => {
  const [open, setOpen] = useState(() => {
    const storageValue = localStorage.getItem(storageKey);
    return Boolean(storageValue);
  });

  const handleToggle = () => {
    setOpen((value) => !value);
  };

  useEffect(() => {
    localStorage.setItem(storageKey, open ? "1" : "");
  }, [open]);

  return {
    open,
    handleToggle
  };
};
