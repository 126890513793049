import { useNavigate } from "react-router-dom";
import { useMeQuery } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useAuthStore } from "../stores/auth";
import { AppGuestRoutes } from "../routes";

export const useCheckAuth = (enabled = true) => {
  const client = useGraphqlClient();
  const navigate = useNavigate();
  const unAuth = useAuthStore((state) => state.unAuth);

  useMeQuery(
    client,
    {},
    {
      enabled,
      staleTime: 60000,
      refetchInterval: 60000,
      onError: () => {
        unAuth();
      },
      onSuccess: (data) => {
        const isAdmin = data?.me?.isAdmin;
        if (isAdmin) return;

        unAuth();

        const clientUrl = process.env.REACT_APP_CLIENT_URL;
        if (clientUrl) {
          window.location.replace(clientUrl);
          return;
        }

        navigate(AppGuestRoutes.Login);
      }
    }
  );
};
