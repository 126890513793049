import { GraphQLClient } from "graphql-request";
import { Response } from "graphql-request/dist/types";
import { curry } from "rambda";
import { AppGuestRoutes } from "~/shared/routes";
import { useAlertsStore as alertsStore } from "~/shared/stores/alerts";
import { useAuthStore, useAuthStore as authStore } from "~/shared/stores/auth";
// import {
//   getDefaultSuccessDelete,
//   getDefaultSuccessUpdate,
//   getDefaultSuccessCreate
// } from "~/shared/lib/messages";
import { createCtx } from "~/shared/lib/context";

const goToLoginPage = () => (window.location = AppGuestRoutes.Login as unknown as Location);

// const handleSuccessResponse = (response: Response<unknown>) => {
//   const data = response.data;
//
//   if (!data) {
//     return;
//   }
//
//   const key = Object.keys(data)[0];
//
//   const isCreate = key.startsWith("create");
//
//   const isUpsert = key.startsWith("upsert");
//
//   const isSettings = key.startsWith("phone");
//
//   const isDelete = key.startsWith("delete");
//
//   if (isCreate) {
//     getDefaultSuccessCreate();
//   }
//
//   if (isUpsert) {
//     getDefaultSuccessUpdate();
//   }
//
//   if (isDelete) {
//     getDefaultSuccessDelete();
//   }
//
//   if (isSettings) {
//     getDefaultSuccessUpdate();
//   }
//
//   return response;
// };

export const gqlClient = new GraphQLClient(process.env.REACT_APP_API_URL ?? "", {
  responseMiddleware: (response) => {
    const addAlert = alertsStore.getState().addAlert;

    const unAuth = authStore.getState().unAuth;

    const addErrorAlert = curry(addAlert)("error");

    const errors = (response as unknown as { response: Response<unknown> }).response?.errors;

    const categoryError = errors?.[0]?.extensions.category;

    // const validationErrors: Record<string, string[]> = errors?.[0]?.extensions
    //   ?.validation as Record<string, string[]>;

    const isAuthError =
      (categoryError === "internal" && errors?.[0]?.path?.[0] === "me") ||
      categoryError === "authentication";

    if (isAuthError) {
      addErrorAlert("Error authorization");
      unAuth();
      goToLoginPage();
      return;
    }

    // Успешные и ошибки должны отрабатыватся на другом уровне
    // пока оставлю для последующегно копирвоания нужного кода

    // if (validationErrors) {
    //   Object.values(validationErrors).forEach(forEach(addErrorAlert));
    //   return;
    // }
    //
    // if (errors) {
    //   errors.forEach(compose(addErrorAlert, prop("message")));
    //   return;
    // }
    //
    // if (response instanceof Error) {
    //   return addErrorAlert(response.message);
    // }
    // if (!(response instanceof Error)) {
    //   handleSuccessResponse(response);
    // }
    // return response;
  }
});

const token = authStore.getState().token;
gqlClient.setHeader("Authorization", `Bearer ${token}`);
gqlClient.setHeader("Apollo-Require-Preflight", "true");

useAuthStore.subscribe((state) => {
  gqlClient.setHeader("Authorization", `Bearer ${state.token}`);
});

export const [useGraphqlClient, GraphQlClientProvider] = createCtx(gqlClient, {
  name: "GraphQlClientProvider"
});
