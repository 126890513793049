import React from "react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { AppProtectedRoutes } from "~/shared/routes";
import { Logo } from "~shared/components/Logo";

type Props = {
  className?: string;
};

export const DrawerHeader: React.FC<Props> = ({ className = "" }) => (
  <Link
    to={AppProtectedRoutes.Home}
    className={clsx("flex justify-center items-center px-1 min-h-[50px]", className)}
  >
    <Logo className='w-full grow max-w-[183px]' />
  </Link>
);
